import { TokenIDType } from "./WalletType";
import { TradePair, UserData } from "./contextType";

export enum BuySellType {
  BUY = "BUY",
  SELL = "SELL",
}
export interface SearchTokenID {
  trade_pair_id: number;
  order_side: string;
}

export enum OrderType {
  LIMIT = "LIMIT",
  MARKET = "MARKET",
}

export interface TransactionAPIType {
  trade_pair_id: string;
  user_id: string;
  order_side: BuySellType;
  order_type: { label: string; value: string };
  price: number;
  amount: number;
  filled: number;
  status: "unfulfilled";
  is_active: boolean;
  expiry: string;
  order_placement_time: string;
}

export interface PendingOrderType {
  amount: number;
  expiry: string;
  filled: number;
  id: number;
  is_active: boolean;
  order_placement_time: string;
  order_side: string;
  order_type: string;
  price: number;
  status: string;
  token_idqty: any;
  trade_pair_id: number;
  user_id: number;
}

export interface TradeBuySellProps {
  userID: UserData | null;
  tradePairID: TradePair | undefined;
  tokenIDs: TokenIDType[];
  setTokenIDs: React.Dispatch<React.SetStateAction<TokenIDType[]>>;
  selectedBuySellMode: BuySellType;
  setSelectedBuySellMode: React.Dispatch<React.SetStateAction<BuySellType>>;
  resizeMode?: boolean;
  style?: React.CSSProperties;
  refresh_numbers?: string;
}

export interface CurrentBalanceType {
  asset_id: number;
  asset_name: string;
  is_currency: boolean;
  user_balance: number | null;
}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//       background: "black",
//       Checkbox: {
//         color: "white",
//       },
//     },
//   },
// };
