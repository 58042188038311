import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/custom.css";
import UserProvider from "./hooks/UserProvider";
import { Route, Routes, useNavigate } from "react-router-dom";
// import ProtectedRoute from "./Routes/ProtectedRoute";
// import { AccessControl } from "./helpers/types/role";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import { Login } from "./Pages/AuthenticationPages/Login";
import ProtectedRoute from "./Routes/ProtectedRoute";
import { AccessControl } from "./helpers/types/role";
import wallet from "./Pages/Wallet/wallet";
import TokenizeCredit from "./Pages/Wallet/TokenizeCredit";
import Profile from "./Pages/Profile/Profile";
import MobileNavbar from "./layout/MobileNavbar";
import useBreakPoints from "./hooks/useBreakPoints";
import AddAssets from "./Pages/Assets/AddAssets";
import CreditRequestManagement from "./Pages/AdminManagement/CreditRequestManagement";
import CorporateRequestManagement from "./Pages/AdminManagement/CorporateRequestManagement";
import Transactions from "./Pages/Transaction/Transactions";
import MyOrders from "./Pages/MyOrders/MyOrders";
import ExploreTradePair from "./Pages/ExploreTradePair/ExploreTradePair";
// import { CookieItems, GetCookies } from "./helpers/cookies_helper";
import { useEffect, useState } from "react";
// import { UserContext } from "./helpers/types/contextType";
import Signup from "./Pages/AuthenticationPages/Signup";
import { ResetPassword } from "./Pages/AuthenticationPages/ResetPassword";
import MyCreditRequest from "./Pages/MyCreditRequest/MyCreditRequest";
import Trading from "./Pages/Trading/Index";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { SafeArea } from "capacitor-plugin-safe-area";
import ClosedSidebar from "./layout/ClosedSidebar";
import { CookieItems, GetCookies } from "./helpers/cookies_helper";
function MainApp() {
  const { isMobile } = useBreakPoints();
  const navigate = useNavigate();

  const jwt = GetCookies(CookieItems.JWT);
  const [safeAreaInsets, setSafeAreaInsets] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setOverlaysWebView({ overlay: true });
      App.addListener("backButton", handleBackBtn);
      window.addEventListener("resize", handleOrientationChange);
    }
    return () => {
      if (Capacitor.isNativePlatform()) {
        App.removeAllListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      getSafeAreaInsets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSafeAreaInsets() {
    const { insets } = await SafeArea.getSafeAreaInsets();
    setSafeAreaInsets(insets);
  }

  const handleOrientationChange = () => {
    // Add a small delay to ensure the orientation change is complete
    setTimeout(getSafeAreaInsets, 100);
  };

  const handleBackBtn = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      App.exitApp();
    }
  };

  return (
    <div
      className="app"
      style={{
        minHeight: "100vh",
        paddingTop: `${safeAreaInsets.top}px`,
        paddingBottom: `${safeAreaInsets.bottom}px`,
        paddingLeft: `${safeAreaInsets.left}px`,
        paddingRight: `${safeAreaInsets.right}px`,
      }}
    >
      <UserProvider>
        <header>
          {/* <HeaderComponent /> */}
          <Header />
        </header>

        <div
          className="d-flex justify-content-start align-items-start w-100"
          style={{
            height: jwt ? "calc( 100vh - 64px )" : "auto",
            overflowY: "auto",
          }}
        >
          {!isMobile && jwt && <ClosedSidebar />}
          <div
            style={{
              width: isMobile ? "100%" : jwt ? "calc( 100% - 70px )" : "100%",
            }}
          >
            <Routes>
              <Route path="/signin" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/wallet"
                element={
                  <ProtectedRoute
                    component={wallet}
                    acceptedRoles={AccessControl.wallet}
                  />
                }
              />
              <Route
                path="/tokenize-credit"
                element={
                  <ProtectedRoute
                    component={TokenizeCredit}
                    acceptedRoles={AccessControl.wallet}
                  />
                }
              />

              <Route
                path="/logout"
                element={<ProtectedRoute component={Login} LogoutFlag={true} />}
              />

              <Route
                path="/profile"
                element={
                  <ProtectedRoute
                    component={Profile}
                    acceptedRoles={AccessControl.profile}
                  />
                }
              />

              <Route
                path="/addassets"
                element={
                  <ProtectedRoute
                    component={AddAssets}
                    acceptedRoles={AccessControl.admin}
                  />
                }
              />

              <Route
                path="/credit-request-management"
                element={
                  <ProtectedRoute
                    component={CreditRequestManagement}
                    acceptedRoles={AccessControl.admin}
                  />
                }
              />

              <Route
                path="/corporate-request-management"
                element={
                  <ProtectedRoute
                    component={CorporateRequestManagement}
                    acceptedRoles={AccessControl.corprequestmngmt}
                  />
                }
              />
              <Route
                path="/transactions"
                element={
                  <ProtectedRoute
                    component={Transactions}
                    acceptedRoles={AccessControl.transactions}
                  />
                }
              />

              <Route
                path="/myorders"
                element={
                  <ProtectedRoute
                    component={MyOrders}
                    acceptedRoles={AccessControl.myorders}
                  />
                }
              />
              <Route path="/explore" element={
                <ProtectedRoute
                  component={ExploreTradePair}
                  acceptedRoles={AccessControl.explore}
                />
              }
              />

              <Route
                path="/myrequests"
                element={
                  <ProtectedRoute
                    component={MyCreditRequest}
                    acceptedRoles={AccessControl.myrequests}
                  />
                }
              />

              <Route path="/trading/:tradePairId" element={<Trading />} />

              {/*   
           
          



            {/* WILDCARD FOR EVERY OTHER ROUTE */}
              <Route path="*" element={<Login />} />
            </Routes>
          </div>
        </div>

        {isMobile && <MobileNavbar />}
        <Footer />
      </UserProvider>
    </div>
  );
}

export default MainApp;
