import Cookies from 'js-cookie';

// TO_Unit is an enum that contains the time unit for the cookie timeout
enum TO_Unit {
    Minutes = "MINUTES",
    HOURS = "HOURS",
    DAYS = "DAYS",
}

//  CookieItems is an enum that contains all the cookie keys
export enum  CookieItems {
    JWT =  "jwt",
    USERID = "userID",
    EMAIL = "email",
    ROLE = "role",
}

export function SetCookies(key: string, value: string, timeoutValue: number, timeoutUnit: string) {
    // Input validation
    if (!key || !value || timeoutValue <= 0) {
        throw new Error("Invalid input parameters");
    }

    var expirationTime: Date;

    if (timeoutUnit === TO_Unit.Minutes) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 60000);
    } else if (timeoutUnit === TO_Unit.HOURS) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 3600000);
    } else if (timeoutUnit === TO_Unit.DAYS) {
        expirationTime = new Date(new Date().getTime() + timeoutValue * 86400000);
    } else {
        throw new Error("Invalid timeoutUnit");
    }

    const isLocalhost = window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';

    Cookies.set(key, value, {
        expires: expirationTime,
        secure: !isLocalhost, // false for localhost, true for production
        sameSite: isLocalhost ? 'lax' : 'strict', // less restrictive for localhost
        path: '/',
        // httpOnly: true - Note: Can't be set from client-side JavaScript
    });
}

export function GetCookies(key: CookieItems): string | undefined {
    return Cookies.get(key);
}

export function DeleteCookie(cookieName: CookieItems): void {
    Cookies.remove(cookieName);
  }