import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
// import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { useNavigate } from "react-router-dom";
import {
  BuySellType,
  CurrentBalanceType,
  OrderType,
  TradeBuySellProps,
  TransactionAPIType,
} from "../../helpers/types/BuySellType";
import {
  BASE_URL,
  BUYER_FEES_MULTIPLIER,
  GET_CURRENT_BALANCE,
  GET_PERCENTAGE_HOLDING_EP,
  GET_TOKEN_ID_FAVOURITE,
  GET_TOKEN_ID_TRADE_PAIR,
  PLACE_LIMIT_ORDER_EP,
  PLACE_MARKET_ORDER_EP,
  // SEARCHTOKENID,
  SELLER_FEES_MULTIPLIER,
} from "../../helpers/api_helper";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
// import { debounce } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // FormGroup,
  Tooltip,
  Typography,
  createTheme,
} from "@mui/material";
import { TokenIDType } from "../../helpers/types/WalletType";
import { Col, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import InfoIcon from "@mui/icons-material/Info";
import CustomDropdown from "../../Component/CustomDropdown";

import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import ToolTipIcon from "./ToolTipIcon";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

const orderTypeOptions = [
  { label: OrderType.LIMIT, value: OrderType.LIMIT },
  { label: OrderType.MARKET, value: OrderType.MARKET },
];

const BuySellContainer = (props: TradeBuySellProps) => {
  console.log(props);
  const { isTablet, isMobile, isSmallMobile } = useBreakPoints();
  const handleError = useErrorHandler();
  const role = GetCookies(CookieItems.ROLE);
  const navigate = useNavigate();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "rgb(114,114,114)",
        light: "white",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.3)", // Lighter color for disabled state
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "white",
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.3)", // Lighter color for disabled state
            },
          },
          colorSecondary: {
            color: "white",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          colorPrimary: {
            color: "white",
          },
          colorSecondary: {
            color: "white",
          },
          colorAction: {
            color: "white",
          },
          root: {
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.3)", // Lighter color for disabled state
            },
          },
        },
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transactionError, setTransactionError] = React.useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [walletPercentage, setWalletPercentage] = React.useState<number>(0);
  const [transactionData, setTransactionData] =
    React.useState<TransactionAPIType>({
      trade_pair_id: props.tradePairID?.id?.toString() || "0",
      user_id: "",
      order_side: props.selectedBuySellMode,
      order_type: { label: OrderType.LIMIT, value: OrderType.LIMIT },
      price: 0,
      amount: 0,
      filled: 0,
      status: "unfulfilled",
      is_active: true,
      expiry: "",
      order_placement_time: "",
    });
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(Date.now()) // Adds one minute to the current date
  );
  const [currentBalance, setCurrentBalance] = useState<CurrentBalanceType>({
    asset_id: 0,
    asset_name: "",
    is_currency: false,
    user_balance: null,
  });

  const [userBuyBalance, setUserBuyBalance] = useState<number>(0);

  const [loading, setLoading] = useState({
    cancel: false,
    "buy-sell": false,
  });
  const [tokenIDs, settokenIDs] = useState<
    { label: string; value: TokenIDType }[]
  >([]);
  const [favoriteTokenIDs, setFavoriteTokenIDs] = useState<number[]>([]);
  const [selectedTokenID, setSelectedTokenID] = useState<
    { label: string; value: TokenIDType }[]
  >([]);
  const [variable_tk_id_check, setVariableTkIdCheck] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshTkID, setRefreshTkID] = useState("");
  function handleTransaction(): void {
    setLoading((prevState) => ({
      ...prevState,
      "buy-sell": true,
    }));

    let arr: any = [];

    selectedTokenID.forEach((token) => {
      arr.push(token.value.token_id);
    });
    const ep =
      transactionData.order_type?.value === OrderType.LIMIT
        ? PLACE_LIMIT_ORDER_EP
        : PLACE_MARKET_ORDER_EP;
    axios
      .post(
        BASE_URL + ep,
        {
          trade_pair_id: props.tradePairID?.id || "0",
          user_id: null,
          order_side: props.selectedBuySellMode.toLowerCase(),
          order_type: transactionData.order_type?.value.toLowerCase(),
          price:
            transactionData.order_type?.value === OrderType.LIMIT
              ? transactionData.price
              : 0,
          amount: transactionData.amount,
          filled: transactionData.filled,
          status: "unfulfilled",
          is_active: true,
          expiry: selectedDate ? selectedDate.unix() : null,
          order_placement_time: moment().unix(),
          token_idqty: arr.length > 0 ? arr : null,
          variable_qty_tokenid: variable_tk_id_check,
        },
        {
          headers: {
            Authorization: props.userID?.jwt,
          },
        }
      )
      .then((response: any) => {
        setLoading((prevState) => ({
          ...prevState,
          "buy-sell": false,
        }));
        toast.success("Transaction success");
        fetchCurrentBalance();
        fetchUserBuyCurrentBalance();
        setTransactionData({
          trade_pair_id: props.tradePairID?.id?.toString() || "0",
          user_id: "",
          order_side: props.selectedBuySellMode,
          order_type: transactionData?.order_type,
          price: 0,
          amount: 0,
          filled: 0,
          status: "unfulfilled",
          is_active: true,
          expiry: "",
          order_placement_time: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        }
        else {
          console.error("Transaction - buy/sell failed", error);
          toast.error(error.response.data.error);
        }
        setTransactionError("Transaction failed, please try again.");
        setLoading((prevState) => ({
          ...prevState,
          "buy-sell": false,
        }));
      });
  }
  // const [advanceOptions, setAdvanceOptions] = useState<boolean>(false);

  useEffect(() => {
    getFavoriteTokenIDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userID]);

  useEffect(() => {
    if (
      props.tradePairID?.id &&
      props.selectedBuySellMode === BuySellType.SELL
    ) {
      fetchUserBuyCurrentBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tradePairID?.id, props.selectedBuySellMode]);

  const getFavoriteTokenIDs = async () => {
    try {
      const response = await axios.post(
        BASE_URL + GET_TOKEN_ID_FAVOURITE,
        {
          trade_pair_id: props.tradePairID?.id,
        },
        {
          headers: {
            Authorization: props.userID?.jwt,
          },
        }
      );
      setFavoriteTokenIDs(response.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to fetch favorite token IDs", error);
        toast.error("Failed to fetch favorite token IDs");
      }
    }
  };

  const getAllTokenID = async () => {
    if (props.userID && props.tradePairID) {
      try {
        const response = await axios.post(
          BASE_URL + GET_TOKEN_ID_TRADE_PAIR,
          {
            user_id: -1,
            trade_pair_id: props.tradePairID?.id || "0",
            order_side: props.selectedBuySellMode.toLowerCase(),
          },
          {
            headers: {
              Authorization: props.userID?.jwt,
            },
          }
        );

        let arr: any = [];
        response.data.forEach((token: TokenIDType) => {
          if (favoriteTokenIDs.includes(token.token_id)) {
            arr.unshift({ ...token, favourite: true });
          } else {
            arr.push({ ...token, favourite: false });
          }
        });
        const labelValuePair = arr.map((token: any) => ({
          label: token.token_id_name,
          value: token,
        }));
        settokenIDs(labelValuePair);
      } catch (error: any) {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Failed to get percentage available", error);
          handleError(new Error("Failed to get percentage available"));
        }
      }
    }
  };

  // function notBuyLimit(): boolean {
  //   return !(
  //     (props.selectedBuySellMode === BuySellType.BUY)
  //     // && transactionData.order_type?.value === OrderType.MARKET
  //   );
  // }

  useEffect(() => {
    getAllTokenID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.tradePairID?.id,
    props.userID,
    props.selectedBuySellMode,
    refreshTkID,
  ]);

  useEffect(() => {
    setSelectedTokenID([]);
  }, [props.selectedBuySellMode]);

  useEffect(() => {
    if (walletPercentage !== 0 && props.tradePairID !== undefined) {
      axios
        .post(
          BASE_URL + GET_PERCENTAGE_HOLDING_EP,
          {
            user_id: props.userID,
            trade_pair_id: props.tradePairID?.id,
            percent: walletPercentage,
            order_side: props.selectedBuySellMode.toLowerCase(),
          },
          {
            headers: {
              Authorization: props.userID?.jwt,
            },
          }
        )
        .then((response: any) => {
          setTransactionData((prevState) => ({
            ...prevState,
            amount: response.data.amount,
          }));
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error("Failed to get percentage available", error);
            handleError(new Error("Failed to get percentage available"));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletPercentage]);

  function fetchCurrentBalance() {
    if (props.tradePairID !== undefined) {
      axios
        .post(
          BASE_URL + GET_CURRENT_BALANCE,
          {
            user_id: null,
            trade_pair_id: props.tradePairID?.id || "0",
            order_side: props.selectedBuySellMode.toLowerCase(),
          },
          {
            headers: {
              Authorization: props.userID?.jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.data !== null && response.data !== undefined) {
            setCurrentBalance(response.data);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            // toast.error("Session expired, please login again", {toastId : "sessionExp"});
            // localStorage.clear();
            // navigate("/logout");
          } else {
            console.error(error);
            setCurrentBalance((prevState) => ({
              ...prevState,
              user_balance: null,
            }));
          }
        });
    }
  }
  function fetchUserBuyCurrentBalance() {
    if (props.tradePairID !== undefined) {
      axios
        .post(
          BASE_URL + GET_CURRENT_BALANCE,
          {
            user_id: null,
            trade_pair_id: props.tradePairID?.id || "0",
            order_side: BuySellType.BUY.toLowerCase(),
          },
          {
            headers: {
              Authorization: props.userID?.jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.data !== null && response.data !== undefined) {
            setUserBuyBalance(response.data.user_balance);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            // toast.error("Session expired, please login again", {toastId : "sessionExp"});
            // localStorage.clear();
            // navigate("/logout");
          } else {
            console.error(error);
            setCurrentBalance((prevState) => ({
              ...prevState,
              user_balance: null,
            }));
          }
        });
    }
  }

  useEffect(() => {
    if (props.tradePairID !== undefined) {
      axios
        .post(
          BASE_URL + GET_CURRENT_BALANCE,
          {
            user_id: null,
            trade_pair_id: props.tradePairID?.id || "0",
            order_side: props.selectedBuySellMode.toLowerCase(),
          },
          {
            headers: {
              Authorization: props.userID?.jwt,
            },
          }
        )
        .then((response: any) => {
          if (response.data !== null && response.data !== undefined) {
            setCurrentBalance(response.data);
          }
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            // toast.error("Session expired, please login again", {toastId : "sessionExp"});
            // localStorage.clear();
            // navigate("/logout");
          } else {
            console.error(error);
            setCurrentBalance((pevState) => ({
              ...pevState,
              user_balance: null,
            }));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBuySellMode, props.tradePairID?.id, props.userID]);

  return (
    <ThemeProvider theme={darkTheme}>    
    <div>
      {role?.startsWith("unverified") ? (
        <div
            style={{
              ...props.style,
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            border: "1px solid #313d4f",
            transition: "transform 0.3s ease-in-out",
            height: props.resizeMode ? "100%" : "auto",
            width: props.resizeMode ? "100%" : "auto",
          }}
        >
          <div
            className={`d-flex justify-content-between align-items-center w-100 ${
              isSmallMobile ? "flex-column gap-3" : ""
            }`}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: 24,
                lineHeight: "20px",
                fontWeight: 700,
              }}
            >
              Trade{" "}
            </Typography>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100  flex-column">
            <ToolTipIcon
              place={isMobile ? "top" : "right"}
              style={{ fontSize: "40px", color: "yellow", textWrap: "balance" }}
              iconName="fa fa-exclamation-circle"
              content="Dear user, please complete your KYC and user verification to ensure regulatory compliance. click the button below to be redirected to the profile page where you can complete your KYC"
              id="unverified-tooltip"
            />
            <span className="mt-2">
              You are not verified yet. Please verify your account to place
              orders.
            </span>

            <CustomButton
              style={{ marginTop: 10, marginBottom: 10 }}
              placeholder="Verify Account"
              color="#3cab3d"
              onClick={() => {
                navigate("/profile");
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#273142",
            borderRadius: 10,
            padding: "10px 20px",
            border: "1px solid #313d4f",
            transition: "transform 0.3s ease-in-out",
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                overflow: 'auto',
                ...props.style // Make sure to spread any additional styles passed as props
          }}
        >
          <div
            className={`d-flex justify-content-between align-items-center w-100 ${
              isSmallMobile ? "flex-column gap-3" : ""
            }`}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: 24,
                lineHeight: "20px",
                fontWeight: 700,
              }}
            >
              Trade{" "}
            </Typography>

            <div>
              <button
                type="button"
                onClick={() => {
                  props.setSelectedBuySellMode(BuySellType.BUY);
                }}
                style={{
                  outline:
                    props.selectedBuySellMode === BuySellType.BUY
                      ? "1px solid #1dbbac"
                      : "1px solid #1dbbac",
                  border: "none",
                  background:
                    props.selectedBuySellMode === BuySellType.BUY
                      ? "#1dbbac"
                      : "#323d4e",

                  padding: "7px 30px",
                  fontWeight: 700,
                  color: "white",
                  fontSize: 15,
                  borderRadius: "5px 0px 0px 5px",
                }}
              >
                {BuySellType.BUY}
              </button>
              <button
                type="button"
                onClick={() => {
                  props.setSelectedBuySellMode(BuySellType.SELL);
                }}
                style={{
                  border:
                    props.selectedBuySellMode === BuySellType.BUY
                      ? "1px solid #e94952"
                      : "1px solid #e94952",
                  borderLeft: "none",
                  fontSize: 15,
                  background:
                    props.selectedBuySellMode === BuySellType.SELL
                      ? "#e94952"
                      : "#323d4e",

                  padding: "7px 30px",
                  fontWeight: 700,
                  color: "white",
                  borderRadius: "0px 5px 5px 0px",
                }}
              >
                {BuySellType.SELL}
              </button>
            </div>
          </div>

          <Row className={`${isTablet ? "gap-3" : ""} mt-3`}>
            <Col lg={6} md={12} sm={6}>
              <Label>Quantity </Label>
              <Tooltip
                title="The number of credits you want to buy or sell"
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#475469",
                    },
                  },
                }}
              >
                <InfoIcon
                  style={{
                    cursor: "pointer",
                    fontSize: 16,
                    marginLeft: 5,
                    marginBottom: 3,
                  }}
                />
              </Tooltip>
              <CustomInputBox
                value={
                  transactionData.amount !== -1
                    ? transactionData.amount.toString()
                    : ""
                }
                onchange={(e: any) =>
                  setTransactionData((prevState) => ({
                    ...prevState,
                    amount: Math.floor(Number(e.target.valueAsNumber)),
                  }))
                }
                placeholder="Quantity"
                className="form-control"
                type="number"
              />
            </Col>

            <Col lg={6} md={12} sm={6}>
              <Label> Select Order Type</Label>
              <CustomDropdown
                options={orderTypeOptions}
                value={transactionData.order_type}
                onChange={(e: any) => {
                  setTransactionData((prevState) => ({
                    ...prevState,
                    order_type: e,
                  }));
                }}
                    isDisabled={false}
              />
            </Col>

            <Col lg={6} md={12} sm={6} className={`${isTablet ? "" : "mt-3"}`}>
              <Label>Price</Label>
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div
                  style={{
                    backgroundColor: "#323d4e",
                        padding: "4px 18px", 
                    fontSize: "25px",
                    borderRadius: "5px",
                        cursor: transactionData.order_type?.value === OrderType.MARKET ? "not-allowed" : "pointer",
                        opacity: transactionData.order_type?.value === OrderType.MARKET ? 0.5 : 1
                  }}

                  onClick={() => {
                    if (transactionData.order_type?.value !== OrderType.MARKET) {
                      setTransactionData((prevState) => ({
                        ...prevState,
                        price: prevState.price - 0.5,
                      }));
                    }
                  }}
                >
                  <span>-</span>
                </div>
                <CustomInputBox
                  value={transactionData.price.toString()}
                  onchange={(e) => {
                    if (transactionData.order_type?.value !== OrderType.MARKET) {
                      setTransactionData((prevState) => ({
                        ...prevState,
                        price: Number(e.target.value),
                      }));
                    }
                  }}
                  placeholder="Price"
                      className={`form-control text-center ${transactionData.order_type?.value === OrderType.MARKET ? 'disabled' : ''}`}
                  type="number"
                      disabled={transactionData.order_type?.value === OrderType.MARKET}
                      style={{
                        opacity: transactionData.order_type?.value === OrderType.MARKET ? 0.5 : 1,
                        cursor: transactionData.order_type?.value === OrderType.MARKET ? 'not-allowed' : 'auto',
                        backgroundColor: transactionData.order_type?.value === OrderType.MARKET ? '#2a2f38' : '#18202f',
                        color: transactionData.order_type?.value === OrderType.MARKET ? '#7a7a7a' : '#ffffff'
                      }}
                />

                <div
                  style={{
                    backgroundColor: "#323d4e",
                    padding: "4px 18px",
                        fontSize: "25px", 
                    borderRadius: "5px",
                        cursor: transactionData.order_type?.value === OrderType.MARKET ? "not-allowed" : "pointer",
                        opacity: transactionData.order_type?.value === OrderType.MARKET ? 0.5 : 1
                  }}
                  onClick={() => {
                    if (transactionData.order_type?.value !== OrderType.MARKET) {
                      setTransactionData((prevState) => ({
                        ...prevState,
                        price: prevState.price + 0.5,
                      }));
                    }
                  }}
                >
                  <span>+</span>
                </div>
              </div>
            </Col>
                <Col lg={6} md={12} sm={6} className={`${isTablet ? "" : "mt-3"}`}>
                  <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <MobileDateTimePicker
                          className="mt-3"
                          label="Expiry"
                          value={selectedDate}
                          format="YYYY-MM-DD HH:mm"
                          onChange={(newValue: any) => {
                            setSelectedDate(newValue);
                          }}
                          // minDateTime={dayjs().add(1, 'minute')} // Prevent selecting current minute or past
                          disabled={transactionData.order_type?.value === OrderType.MARKET}
                          slotProps={{
                            layout: {
                              sx: {
                                backgroundColor: transactionData.order_type?.value === OrderType.MARKET ? "#2a2f38" : "#18202f",
                                border: "1px solid #18202f",
                                borderRadius: "15px",
                                color: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff",
                                marginTop: "0.5%",
                                "& .Mui-disabled": {
                                  color: "#7a7a7a !important",
                                  "-webkit-text-fill-color": "#7a7a7a !important"
                                }
                              }
                            },
                            dialog: {
                              sx: {
                                "& .MuiPaper-root": {
                                  backgroundColor: "#18202f",
                                  color: "#ffffff"
                                },
                                "& .MuiPickersDay-root": {
                                  color: "#ffffff",
                                  "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.1)"
                                  },
                                  "&.Mui-selected": {
                                    backgroundColor: "#1565C0",
                                    color: "#ffffff"
                                  }
                                },
                                "& .MuiDayPicker-weekDayLabel": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersArrowSwitcher-button": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersYear-yearButton": {
                                  color: "#ffffff",
                                  "&.Mui-selected": {
                                    backgroundColor: "#1565C0"
                                  }
                                },
                                "& .MuiPickersMonth-monthButton": {
                                  color: "#ffffff",
                                  "&.Mui-selected": {
                                    backgroundColor: "#1565C0"
                                  }
                                },
                                "& .MuiClock-pin": {
                                  backgroundColor: "#1565C0"
                                },
                                "& .MuiClockPointer-root": {
                                  backgroundColor: "#1565C0"
                                },
                                "& .MuiClockPointer-thumb": {
                                  backgroundColor: "#1565C0",
                                  border: "16px solid #1565C0"
                                },
                                "& .MuiClock-clock": {
                                  backgroundColor: "#18202f"
                                },
                                "& .MuiClockNumber-root": {
                                  color: "#ffffff",
                                  "&.Mui-selected": {
                                    backgroundColor: "#1565C0",
                                    color: "#ffffff"
                                  }
                                },
                                "& .MuiTypography-root": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersCalendarHeader-label": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersYear-root": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersMonth-root": {
                                  color: "#ffffff"
                                },
                                "& .MuiDateTimePickerToolbar-dateContainer": {
                                  color: "#ffffff"
                                },
                                "& .MuiDateTimePickerToolbar-timeDigitsContainer": {
                                  color: "#ffffff"
                                },
                                "& .MuiDateTimePickerToolbar-ampmSelection": {
                                  color: "#ffffff"
                                },
                                "& .MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#1565C0",
                                  color: "#ffffff"
                                },
                                "& .MuiTabList-root .Mui-selected": {
                                  color: "#1565C0"
                                },
                                "& .MuiPickersYear-yearButton.Mui-selected": {
                                  backgroundColor: "#1565C0",
                                  color: "#ffffff"
                                },
                                "& .MuiPickersMonth-monthButton.Mui-selected": {
                                  backgroundColor: "#1565C0",
                                  color: "#ffffff"
                                },
                                "& .MuiDateTimePickerToolbar-ampmSelection .Mui-selected": {
                                  color: "#1565C0"
                                },
                                "& .MuiPickersYear-root.Mui-selected": {
                                  backgroundColor: "#1565C0 !important",
                                  color: "#ffffff !important"
                                },
                                "& .MuiPickersMonth-root.Mui-selected": {
                                  backgroundColor: "#1565C0 !important",
                                  color: "#ffffff !important"
                                },
                                "& .MuiDateTimePickerToolbar-root": {
                                  "& .Mui-selected": {
                                    color: "#1565C0 !important"
                                  },
                                  "& .MuiTypography-root.Mui-selected": {
                                    color: "#1565C0 !important"
                                  }
                                },
                                "& .MuiClock-root": {
                                  "& .Mui-selected": {
                                    backgroundColor: "#1565C0 !important",
                                    color: "#ffffff !important"
                                  }
                                },
                                "& .MuiClockNumber-root.Mui-selected": {
                                  backgroundColor: "#1565C0 !important",
                                  color: "#ffffff !important"
                                },
                                "& .MuiTimePickerToolbar-hourMinuteLabel": {
                                  "& .Mui-selected": {
                                    color: "#1565C0 !important"
                                  }
                                },
                                "& .MuiTabs-root": {
                                  "& .MuiTab-root": {
                                    color: "#ffffff",
                                    "& .MuiSvgIcon-root": {
                                      color: "#ffffff"
                                    }
                                  },
                                  "& .Mui-selected": {
                                    color: "#1565C0",
                                    "& .MuiSvgIcon-root": {
                                      color: "#1565C0"
                                    }
                                  }
                                },
                                "& .MuiDateTimePickerTabs-root": {
                                  borderColor: "#ffffff",
                                  "& .MuiTabs-indicator": {
                                    backgroundColor: "#1565C0"
                                  }
                                },
                                "& .MuiPickersLayout-actionBar button": {
                                  color: "#1565C0"
                                },
                                "& .MuiTabs-flexContainer button": {
                                  color: "#ffffff",
                                  "&.Mui-selected": {
                                    color: "#1565C0"
                                  }
                                },
                                "& .MuiPickersLayout-root": {
                                  minWidth: "280px",
                                  minHeight: "400px",
                                  overflow: "visible"
                                },
                                "& .MuiPickersLayout-contentWrapper": {
                                  overflow: "visible",
                                  minHeight: "350px"
                                },
                                "& .MuiDateCalendar-root": {
                                  width: "auto",
                                  minHeight: "300px",
                                  overflow: "visible"
                                },
                                "& .MuiPickersCalendarHeader-root": {
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                  marginTop: "8px",
                                  color: "#ffffff"
                                },
                                "& .MuiDayCalendar-monthContainer": {
                                  minHeight: "220px"
                                },
                                "& .MuiPickersPopper-root": {
                                  position: "absolute",
                                  inset: "auto auto 0px 0px",
                                  transform: "none !important"
                                }
                              }
                            },
                            textField: {
                              sx: {
                                "& .MuiInputBase-root": {
                                  color: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  "& input": {
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    paddingRight: "14px",  // Add some padding to prevent text from touching the edge
                                    "&.Mui-disabled": {
                                      color: "#7a7a7a !important",
                                      "-webkit-text-fill-color": "#7a7a7a !important"
                                    }
                                  },
                                  "& fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  },
                                  "&:hover fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  }
                                },
                                "& .MuiInputLabel-root": {
                                  color: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                },
                                "& .MuiSvgIcon-root": {
                                  color: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                },
                                "& input": {
                                  color: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                },
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  },
                                  "&:hover fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: transactionData.order_type?.value === OrderType.MARKET ? "#7a7a7a" : "#ffffff"
                                  }
                                },
                                "& .Mui-disabled": {
                                  color: "#7a7a7a !important",
                                  "-webkit-text-fill-color": "#7a7a7a !important"
                                },
                                "& .Mui-selected": {
                                  color: "#1565C0"
                                },
                                "& .MuiOutlinedInput-input": {
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                },
                                width: "100%",  // Ensure the field takes full width of container
                                maxWidth: "100%"  // Prevent overflow
                              }
                            }
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </ThemeProvider>
            </Col>
            {props.selectedBuySellMode === BuySellType.SELL &&
              selectedTokenID.length > 0 && (
                <Col md={6} className="mt-3">
                  <Label>Token Id Validation</Label>{" "}
                  <Tooltip
                    title="Strict token ID matching: If enabled, orders are only placed if sufficient balance of the specified token IDs is available. If disabled, though the selected token IDs will have preference, other tokens in your wallet may be sold to complete the order if needed."
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#475469",
                        },
                      },
                    }}
                  >
                    <InfoIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 16,
                        marginLeft: 5,
                        marginBottom: 3,
                      }}
                    />
                  </Tooltip>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setVariableTkIdCheck(true);
                      }}
                      style={{
                        border: "none",
                        background: variable_tk_id_check
                          ? "rgb(58 163 81)"
                          : "#323d4e",
                        fontSize: 12,
                        padding: "7px 13px",
                        color: "white",
                        borderRadius: "5px 0px 0px 5px",
                      }}
                    >
                      YES
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setVariableTkIdCheck(false);
                      }}
                      style={{
                        border: "none",
                        background: !variable_tk_id_check
                          ? "#00abff"
                          : "#323d4e",
                        fontSize: 12,
                        padding: "7px 13px",
                        color: "white",
                        borderRadius: "0px 5px 5px 0px",
                      }}
                    >
                      NO
                    </button>
                  </div>
                </Col>
              )}

            <Col
              md={
                props.selectedBuySellMode === BuySellType.SELL &&
                selectedTokenID.length > 0
                  ? 6
                  : 12
              }
              className="d-flex justify-content-end align-items-center w-100 mt-3"
            >
              {props.selectedBuySellMode === BuySellType.BUY && (
                <CustomButton
                  placeholder={BuySellType.BUY}
                  color={"#712FFF"}
                      disabled={
                        transactionData.order_type?.value === OrderType.LIMIT &&
                        (transactionData?.price <= 0 ||
                    transactionData?.amount <= 0 ||
                    (currentBalance.user_balance !== null &&
                      currentBalance.user_balance <
                      (transactionData.amount * transactionData.price) +
                            ((transactionData.amount * transactionData.price * Number(BUYER_FEES_MULTIPLIER)) / 100)))
                  }
                  type="submit"
                  onClick={() => handleTransaction()}
                  style={{ padding: "5px 30px" }}
                  loading={loading["buy-sell"]}
                />
              )}
              {props.selectedBuySellMode === BuySellType.SELL && (
                <CustomButton
                  placeholder={BuySellType.SELL}
                  color={"#712FFF"}
                      disabled={
                        transactionData.order_type?.value === OrderType.LIMIT &&
                        (transactionData?.price <= 0 ||
                          transactionData?.amount <= 0 ||
                          (currentBalance.user_balance !== null &&
                            currentBalance.user_balance < transactionData.amount))
                      }
                  style={{ padding: "5px 30px" }}
                  type="submit"
                  onClick={() => handleTransaction()}
                  loading={loading["buy-sell"]}
                      disabledText="Insufficient Balance"
                />
              )}
            </Col>
          </Row>

          <Accordion
            sx={{
              background: " #273142",
              borderRadius: 2,
              boxShadow: "none",
            }}
            defaultExpanded={true}
            className="mt-3"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                height: "20%",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <Typography sx={{ color: "#ffffff" }}>
                Advanced Options{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ minHeight: "100%" }}>
              <Label className="text-white">Select Token Id</Label>

              <CustomDropdown
                options={tokenIDs}
                value={selectedTokenID}
                onChange={(e: any) => setSelectedTokenID(e)}
                isDisabled={false}
                multiple={true}
                menuPlacement="top"
                customTrade={true}
              />
            </AccordionDetails>
          </Accordion>

          <div>
            <hr />

            {props.selectedBuySellMode === BuySellType.BUY && (
              <Row
                className={`${isMobile ? "gap-4" : ""}`}
                style={{ fontSize: 12 }}
              >
                <Col md={3}>
                  {isMobile ? (
                    <p className="mb-0">
                      Available &nbsp;{" "}
                      {currentBalance.user_balance !== null
                        ? currentBalance.user_balance.toFixed(2)
                        : "Balance Unavailable"}
                      &nbsp; <span>{currentBalance.asset_name || ""}</span>{" "}
                    </p>
                  ) : (
                    <>
                      {" "}
                      <p className="mb-0">Available</p>
                      <p className="mb-0">
                        {currentBalance.user_balance !== null
                          ? currentBalance.user_balance.toFixed(2)
                          : "Balance Unavailable"}
                        &nbsp; <span>{currentBalance.asset_name || ""}</span>
                      </p>
                    </>
                  )}
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Total Amount
                    </p>
                    <p className="mb-0">
                      {(transactionData.amount * transactionData.price).toFixed(
                        2
                      ) || "-"}
                    </p>
                  </div>

                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Fees
                    </p>

                    <p className="mb-0">
                      {" "}
                      {transactionData.amount !== null &&
                      transactionData.price !== null &&
                      transactionData.amount !== 0 &&
                      transactionData.price !== 0
                        ? (
                            (transactionData.amount *
                              transactionData.price *
                              Number(BUYER_FEES_MULTIPLIER)) /
                            100
                          ).toFixed(2)
                        : 0}
                      &nbsp;
                      {props.selectedBuySellMode === BuySellType.BUY &&
                        currentBalance.asset_name}
                    </p>
                  </div>

                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Final Amount
                    </p>

                    <p className="mb-0">
                      {" "}
                      {(() => {
                        const totalAmount =
                          transactionData.amount * transactionData.price;
                        const fees =
                          (totalAmount * Number(BUYER_FEES_MULTIPLIER)) / 100;
                        const finalAmount = totalAmount + fees;
                        return finalAmount.toFixed(2) || "-";
                      })()}
                      &nbsp;
                      {props.selectedBuySellMode === BuySellType.BUY &&
                        currentBalance.asset_name}
                    </p>
                  </div>
                </Col>

                <Col md={5}>
                  <span className="mb-0">
                    {" "}
                    Resultant Balance &nbsp;&nbsp;
                    <span>
                      {(() => {
                        if (currentBalance.user_balance === null) return "-";

                        const totalAmount =
                          transactionData.amount * transactionData.price;
                            const fees = (totalAmount * Number(BUYER_FEES_MULTIPLIER)) / 100;
                            console.log("fees", fees);
                        const finalAmount = totalAmount + fees;
                            console.log("finalAmount", finalAmount);
                            const resultantBalance = currentBalance.user_balance - finalAmount;

                            return resultantBalance.toFixed(2);
                      })()}
                      &nbsp;
                      {currentBalance.asset_name}{" "}
                    </span>
                  </span>
                </Col>
              </Row>
            )}
            {props.selectedBuySellMode === BuySellType.SELL && (
              <Row
                className={`${isMobile ? "gap-4" : ""}`}
                style={{ fontSize: 12 }}
              >
                <Col md={3}>
                  <p className="mb-0">Available</p>
                  <p className="mb-0">
                    {currentBalance.user_balance !== null
                      ? currentBalance.user_balance.toFixed(2)
                      : "Balance Unavailable"}
                    &nbsp; <span>{currentBalance.asset_name || ""}</span>
                  </p>
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Total Amount
                    </p>
                    <p className="mb-0">
                      {transactionData.amount.toFixed(2) || "-"}
                    </p>
                  </div>

                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Fees From Gross Receivable
                    </p>

                    <p className="mb-0">
                      {" "}
                      {transactionData.amount !== null &&
                      transactionData.price !== null &&
                      transactionData.amount !== 0 &&
                      transactionData.price !== 0
                        ? (
                            (transactionData.amount *
                              transactionData.price *
                              Number(SELLER_FEES_MULTIPLIER)) /
                            100
                          ).toFixed(2)
                        : 0}
                    </p>
                  </div>

                  <div className="d-flex justify-content-start gap-3 align-items-center">
                    <p style={{ width: "50%" }} className="mb-0">
                      Final Amount
                    </p>

                    <p className="mb-0">
                      {" "}
                      {(() => {
                        const totalAmount =
                          transactionData.amount * transactionData.price;
                        const fees =
                          (totalAmount * Number(SELLER_FEES_MULTIPLIER)) / 100;
                        const finalAmount = totalAmount - fees;
                        return finalAmount.toFixed(2) || "-";
                      })()}
                      &nbsp;
                    </p>
                  </div>
                </Col>

                <Col md={5}>
                  <p className="mb-0">
                    {" "}
                    Assets Balance <br />
                    <span>
                      {(() => {
                        if (currentBalance.user_balance === null) return "-";

                        const totalAmount =
                          currentBalance.user_balance - transactionData.amount;

                        return totalAmount > 0
                          ? totalAmount.toFixed(2)
                          : "0.00";
                      })()}
                      &nbsp;
                      {currentBalance.asset_name}{" "}
                    </span>
                  </p>
                  <p className="mb-0 mt-2">
                    {" "}
                    Resultant Balance Currency &nbsp;&nbsp;
                    <span>
                      {(() => {
                        if (currentBalance.user_balance === null) return "-";

                        const totalAmount =
                          transactionData.amount * transactionData.price;
                        const fees =
                          (totalAmount * Number(SELLER_FEES_MULTIPLIER)) / 100;
                        const finalAmount = totalAmount - fees;
                        const resultantBalance = userBuyBalance + finalAmount;

                        return resultantBalance > 0
                          ? resultantBalance.toFixed(2)
                          : "0.00";
                      })()}
                      &nbsp;
                          {"INR"}
                    </span>
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      </div>
    </ThemeProvider>
  );
};

export default BuySellContainer;
