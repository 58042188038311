import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, VERIFY_BANK_DETAILS } from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";

const BankVerificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [bankInfo, setBankInfo] = useState({
    name: "",
    remarks: "",
    bankName: "",
    phone: "",
    accountNumber: "",
    ifc: "",
    upiID: "",
  });

  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);

  const validateForm = () => {
    if (!bankInfo.bankName.trim()) {
      toast.error("Bank Name is required");
      return false;
    }
    if (!bankInfo.ifc.trim()) {
      toast.error("IFSC Code is required");
      return false;
    }
    if (!bankInfo.accountNumber.trim()) {
      toast.error("Account Number is required");
      return false;
    }
    if (!bankInfo.phone.trim()) {
      toast.error("Phone Number is required");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please agree to the terms and conditions");
      return;
    }

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    axios
      .post(
        BASE_URL + VERIFY_BANK_DETAILS,
        {
          name: bankInfo.bankName,
          bankAccount: bankInfo.accountNumber,
          ifsc: bankInfo.ifc,
          phone: bankInfo.phone,
          remarks: "",
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res) => {
        toast.success("Bank Details Verified Successfully");
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        if (
          err.response !== undefined &&
          err.response !== null &&
          err.response.data.verification_status === "failed"
        ) {
          toast.error("Bank Details Verification Failed, Please try Again");
        } else if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while verifying Bank Details", err);
          handleError(new Error("Error while verifying Bank Details"));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="lg" isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD YOUR BANK DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row>
          <Col md={6} className="">
            <Label>Bank Name<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.bankName}
              onchange={(e: any) =>
                setBankInfo({
                  ...bankInfo,
                  bankName: e.target.value,
                })
              }
              placeholder="Enter Bank Name"
              className="form-control"
              type="text"
              required={true}
            />
          </Col>
          <Col md={6} className="">
            <Label>IFSC Code<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.ifc}
              onchange={(e: any) =>
                setBankInfo({
                  ...bankInfo,
                  ifc: e.target.value,
                })
              }
              placeholder="Enter IFSC Code"
              className="form-control"
              type="text"
              required={true}
            />
          </Col>
          <Col md={6} className="mt-3">
            <Label>Account Number<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.accountNumber}
              onchange={(e: any) =>
                setBankInfo({
                  ...bankInfo,
                  accountNumber: e.target.value,
                })
              }
              placeholder="Enter Account Number"
              className="form-control"
              type="text"
              required={true}
            />
          </Col>
          <Col md={6} className="mt-3">
            <Label>Phone Number<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.phone}
              onchange={(e: any) =>
                setBankInfo({
                  ...bankInfo,
                  phone: e.target.value,
                })
              }
              placeholder="Enter Phone Number"
              className="form-control"
              type="number"
              required={true}
            />
          </Col>
        </Row>
      </ModalBody>

      <div
        className="p-4 d-flex justify-content-between gap-2"
        style={{ backgroundColor: "#273142" }}
      >
        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            value="terms"
            placeholder="terms"
            required={true}
            checked={terms}
            onChange={(e: any) => {
              setTerms(e.target.checked);
            }}
          />
          <label className="v-aligh">
            I agree to all terms and conditions.
          </label>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <CustomButton
            placeholder="Submit"
            color="#00abff"
            disabled={loading}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BankVerificationModal;
