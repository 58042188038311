// import React from 'react';
import React from "react";
import {
  Button,
  CircularProgress,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material";
import { keyframes } from "@emotion/react";

interface CustomButtonProps {
  placeholder: string;
  color: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  divclassName?: any;
  disabledText?: string;
}
const waveAnimation = keyframes`
  from {
    background-position: 0% 0;
  }
  to {
    background-position: -100% 0;
  }
`;

const CustomButton: React.FC<CustomButtonProps> = ({
  placeholder,
  color,
  onClick,
  type = "button",
  disabled,
  divclassName,
  loading,
  style,
  disabledText, // Add the new optional prop
}) => {
  // Create a theme instance dynamically based on props.color
  const theme = createTheme({
    palette: {
      primary: {
        main: color,
      },
      warning: {
        main: "#3CAB3D",
      },
      info: {
        main: "#008dd2",
      },
    },
  });

  // Custom styled button to maintain opacity
  const StyledButton = styled(Button)(({ theme }) => ({
    "&.Mui-disabled": {
      opacity: loading ? 1 : "",
      color: "#008dd2",
      backgroundColor: "#000b3915",
    },
    "&:hover": {
      backgroundImage: `linear-gradient(45deg, ${
        theme.palette.primary.main === "#008dd2" ||
        theme.palette.primary.main === "#008DD2"
          ? theme.palette.warning.main
          : theme.palette.primary.main
      }, #000b3915, #008dd2)`,
      backgroundSize: "300% 100%",
      animation: `${waveAnimation} 3s ease forwards`,
    },
    transition: "background-color 0.5s ease", // Smooth transition for background color
    backgroundColor:
      theme.palette.primary.main === "#008dd2" ||
      theme.palette.primary.main === "#008DD2"
        ? theme.palette.warning.main
        : theme.palette.primary.main,
    color: "#ffffff",
    overflow: "hidden",
    // Ensure the gradient's end state matches the animation's end state
    backgroundPosition: "-100% 0",
  }));

  return (
    <ThemeProvider theme={theme}>
      <div
        className={divclassName || ""}
        style={{ marginTop: "3px", marginLeft: "3px", marginRight: "3px" }}
      >
        <StyledButton
          variant="contained"
          color="primary"
          onClick={onClick}
          type={type}
          style={style}
          disabled={disabled || loading}
          startIcon={
            loading ? <CircularProgress size={24} color="info" /> : null
          }
        >
          {disabled && disabledText ? disabledText : placeholder}
        </StyledButton>
      </div>
    </ThemeProvider>
  );
};

export default CustomButton;
