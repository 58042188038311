import React, { useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import {
  AssetInputDataType,
  TradingpairInputDataType,
} from "../../helpers/types/AssetsType";
import { toast } from "react-toastify";
import axios from "axios";
import { ADD_ASSETS, ADD_TRADE_PAIR, BASE_URL } from "../../helpers/api_helper";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Component/CustomButton";

const AddAssets = () => {
  const { isMobile, isTablet } = useBreakPoints();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [assetResponse, setAssetResponse] = useState("");
  const [assetInputData, setAssetInputData] = useState<AssetInputDataType>({
    name: "",
    symbol: "",
    is_base: false,
    is_currency: false,
    base_price: null,
    mint_year: null,
    create_contractId: true,
    contractid: null,
  } as AssetInputDataType);

  const [tradingPairInputData, setTradingPairInputData] =
    useState<TradingpairInputDataType>({
      name: "",
      full_name: "",
      asset1_id: null,
      asset2_id: null,
      asset1_iscurrency: false,
      asset2_iscurrency: false,
      mint_year: "",
      tonnage: "",
      registry: "",
      corsia_compatible: "",
      reddplusplus_compatibility: "",
      region: "",
    } as TradingpairInputDataType);

  const handleSubmitAssets = () => {
    if (
      !assetInputData?.name ||
      !assetInputData?.symbol ||
      !assetInputData?.mint_year
    ) {
      toast.error("Please enter all required fields");
      return;
    } else {
      setLoading(true);
      axios
        .post(
          BASE_URL + ADD_ASSETS,
          {
            name: assetInputData?.name,
            symbol: assetInputData?.symbol,
            is_base: assetInputData?.is_base,
            is_currency: assetInputData?.is_currency,
            base_price: assetInputData?.base_price,
            mint_year: assetInputData?.mint_year,
            create_contractid: assetInputData?.create_contractId,
            contractid: assetInputData?.contractid,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((response) => {
          toast.success("Add Assets Successfully");
          setAssetResponse(JSON.stringify(response.data));
          setAssetInputData({
            name: "",
            symbol: "",
            is_base: false,
            is_currency: false,
            base_price: null,
            mint_year: null,
            create_contractId: true,
            contractid: null,
          });
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error("Error while updating status", error);
            toast.error("Error while updating status");
          }
          setLoading(false);
        });
    }
  };
  const handleSubmitTradePair = () => {
    if (
      !tradingPairInputData?.name ||
      !tradingPairInputData?.full_name ||
      !tradingPairInputData?.asset1_id ||
      !tradingPairInputData?.asset2_id ||
      !tradingPairInputData?.mint_year ||
      !tradingPairInputData?.tonnage ||
      !tradingPairInputData?.registry ||
      !tradingPairInputData?.corsia_compatible ||
      !tradingPairInputData?.reddplusplus_compatibility ||
      !tradingPairInputData?.region
    ) {
      toast.error("Please enter all required fields");
      return;
    }
    setLoading(true);
    axios
      .post(
        BASE_URL + ADD_TRADE_PAIR,
        {
          name: tradingPairInputData?.name,
          full_name: tradingPairInputData?.full_name,
          asset1_id: tradingPairInputData?.asset1_id,
          asset2_id: tradingPairInputData?.asset2_id,
          asset1_iscurrency: tradingPairInputData?.asset1_iscurrency,
          asset2_iscurrency: tradingPairInputData?.asset2_iscurrency,
          mint_year: tradingPairInputData?.mint_year,
          tonnage: tradingPairInputData?.tonnage,
          registry: tradingPairInputData?.registry,
          corsia_compatible: tradingPairInputData?.corsia_compatible,
          reddplusplus_compatibility:
            tradingPairInputData?.reddplusplus_compatibility,
          region: tradingPairInputData?.region,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        toast.success("Add Trade Pair Successfully");
        setTradingPairInputData({
          name: "",
          full_name: "",
          asset1_id: null,
          asset2_id: null,
          asset1_iscurrency: false,
          asset2_iscurrency: false,
          mint_year: "",
          tonnage: "",
          registry: "",
          corsia_compatible: "",
          reddplusplus_compatibility: "",
          region: "",
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while updating status", error);
          toast.error("Error while updating status");
        }
        setLoading(false);
      });
  };
  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
          }}
        >
          Add Assets{" "}
        </span>
      </div>

      <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
        <Col lg={6} md={12}>
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              border: "none",
            }}
          >
            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={6} md={12}>
                <Label>Asset Name <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={assetInputData.name}
                  onchange={(e: any) =>
                    setAssetInputData({
                      ...assetInputData,
                      name: e.target.value,
                    })
                  }
                  placeholder="Enter Asset Name"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>Symbol <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={assetInputData.symbol}
                  onchange={(e: any) =>
                    setAssetInputData({
                      ...assetInputData,
                      symbol: e.target.value,
                    })
                  }
                  placeholder="Enter Symbol"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Is Base <span style={{ color: 'red' }}>*</span></Label>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        is_base: true,
                      });
                    }}
                    style={{
                      border: "none",
                      background: assetInputData.is_base
                        ? "rgb(58 163 81)"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        is_base: false,
                      });
                    }}
                    style={{
                      border: "none",
                      background: !assetInputData.is_base
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Is Currency <span style={{ color: 'red' }}>*</span></Label>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        is_currency: true,
                      });
                    }}
                    style={{
                      border: "none",
                      background: assetInputData.is_currency
                        ? "rgb(58 163 81)"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        is_currency: false,
                      });
                    }}
                    style={{
                      border: "none",
                      background: !assetInputData.is_currency
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Mint Year <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={
                    assetInputData.mint_year
                      ? assetInputData.mint_year.toString()
                      : ""
                  }
                  onchange={(e: any) =>
                    setAssetInputData({
                      ...assetInputData,
                      mint_year: e.target.value ? Number(e.target.value) : null,
                    })
                  }
                  placeholder="Enter Mint Year"
                  className="form-control"
                  type="number"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>

              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Create Contract ID <span style={{ color: 'red' }}>*</span></Label>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        create_contractId: true,
                      });
                    }}
                    style={{
                      border: "none",
                      background: assetInputData.create_contractId
                        ? "rgb(58 163 81)"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAssetInputData({
                        ...assetInputData,
                        create_contractId: false,
                      });
                    }}
                    style={{
                      border: "none",
                      background: !assetInputData.create_contractId
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </Col>
              {!assetInputData?.create_contractId && (
                <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                  <Label>Contract ID <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={assetInputData.contractid ?? ""}
                    onchange={(e: any) =>
                      setAssetInputData({
                        ...assetInputData,
                        contractid: e.target.value,
                      })
                    }
                    placeholder="Enter Contract ID"
                    className="form-control"
                    type="text"
                    minLength={0}
                    required={true}
                    maxLength={100}
                  />
                </Col>
              )}

              <Col
                lg={12}
                style={{
                  marginTop: 13,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  placeholder="Add Assets"
                  color="#3cab3d"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleSubmitAssets();
                  }}
                />
              </Col>

              <Col
                lg={12}
                style={{
                  marginTop: 13,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  {assetResponse && (
                    <div className="mt-2 col-md-12">
                      <label>Assets Response</label>
                      <textarea
                        className="form-control"
                        rows={5}
                        value={assetResponse}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg={6} md={12}>
          <div
            style={{
              backgroundColor: "#273142",
              borderRadius: 10,
              padding: "10px 20px",
              border: "none",
            }}
          >
            <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
              <Col lg={6} md={12}>
                <Label>Name <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.name}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      name: e.target.value,
                    })
                  }
                  placeholder="Enter Asset Name"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12}>
                <Label>Full Name <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.full_name}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      full_name: e.target.value,
                    })
                  }
                  placeholder="Enter Full Name"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Asset1 ID <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.asset1_id?.toString() || ""}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      asset1_id: e.target.value,
                    })
                  }
                  placeholder="Enter Asset1 ID"
                  className="form-control"
                  type="number"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Asset2 ID <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.asset2_id?.toString() || ""}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      asset2_id: e.target.value,
                    })
                  }
                  placeholder="Enter Asset2 ID"
                  className="form-control"
                  type="number"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Asset1 Is Currency <span style={{ color: 'red' }}>*</span></Label>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setTradingPairInputData({
                        ...tradingPairInputData,
                        asset1_iscurrency: true,
                      });
                    }}
                    style={{
                      border: "none",
                      background: tradingPairInputData.asset1_iscurrency
                        ? "rgb(58 163 81)"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTradingPairInputData({
                        ...tradingPairInputData,
                        asset1_iscurrency: false,
                      });
                    }}
                    style={{
                      border: "none",
                      background: !tradingPairInputData.asset1_iscurrency
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Asset2 Is Currency <span style={{ color: 'red' }}>*</span></Label>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setTradingPairInputData({
                        ...tradingPairInputData,
                        asset2_iscurrency: true,
                      });
                    }}
                    style={{
                      border: "none",
                      background: tradingPairInputData.asset2_iscurrency
                        ? "rgb(58 163 81)"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setTradingPairInputData({
                        ...tradingPairInputData,
                        asset2_iscurrency: false,
                      });
                    }}
                    style={{
                      border: "none",
                      background: !tradingPairInputData.asset2_iscurrency
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Mint Year <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.mint_year}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      mint_year: e.target.value,
                    })
                  }
                  placeholder="Enter Mint Year"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Tonnage <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.tonnage}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      tonnage: e.target.value,
                    })
                  }
                  placeholder="Enter Tonnage"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Registry <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.registry}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      registry: e.target.value,
                    })
                  }
                  placeholder="Enter Registry"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Corsia Compatible <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.corsia_compatible}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      corsia_compatible: e.target.value,
                    })
                  }
                  placeholder="Enter Corsia Compatible"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Red Plus Compatibility <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.reddplusplus_compatibility}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      reddplusplus_compatibility: e.target.value,
                    })
                  }
                  placeholder="Enter Red Plus Compatibility"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-3"}`}>
                <Label>Region <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  value={tradingPairInputData.region}
                  onchange={(e: any) =>
                    setTradingPairInputData({
                      ...tradingPairInputData,
                      region: e.target.value,
                    })
                  }
                  placeholder="Enter Region"
                  className="form-control"
                  type="text"
                  minLength={0}
                  required={true}
                  maxLength={100}
                />
              </Col>

              <Col
                lg={12}
                md={12}
                className={`${isTablet ? "" : "mt-3"}`}
                style={{
                  marginTop: 13,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <CustomButton
                  placeholder="Add Trade Pair"
                  color="#3cab3d"
                  style={{
                    marginTop: 13,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleSubmitTradePair();
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddAssets;
